import { ImageSizeMap } from '#imports';
import { Enum_Componentsharedmetasocial_Socialnetwork, type SharedSeoFragment } from '~/types/graphql';

export default function useCmsMetaTags(data?: SharedSeoFragment) {
  const config = useRuntimeConfig();
  const { getAssetUrl } = useCmsAsset();
  const { fullPath } = useRoute();

  const url = computed<string>(() => {
    return `${config.public.legacyUrl}${fullPath}`;
  });

  function getMetaSocial(
    socialNetworkType: Enum_Componentsharedmetasocial_Socialnetwork,
  ): NonNullable<SharedSeoFragment['metaSocial']>[number] | undefined {
    return data?.metaSocial?.find((element) => element?.socialNetwork === socialNetworkType);
  }

  const ogMetaData = getMetaSocial(Enum_Componentsharedmetasocial_Socialnetwork.Facebook);
  const twitterMetaData = getMetaSocial(Enum_Componentsharedmetasocial_Socialnetwork.Twitter);

  const DefaultMetaImageModifiers = ImageSizeMap.metaImage;

  const defaultImage = getAssetUrl(data?.metaImage, DefaultMetaImageModifiers) ?? undefined;
  const ogImage = getAssetUrl(ogMetaData?.image, DefaultMetaImageModifiers) ?? defaultImage;
  const twitterImage = getAssetUrl(twitterMetaData?.image, DefaultMetaImageModifiers) ?? defaultImage;

  // TODO retrieve metatags from global config, and set as default when empty

  useSeoMeta({
    title: data?.metaTitle,
    description: data?.metaDescription,
    ogType: 'website',
    ogLocale: 'fr_FR',
    ogSiteName: 'Onliz',
    ogTitle: ogMetaData?.title ?? data?.metaTitle,
    ogDescription: ogMetaData?.description ?? data?.metaDescription,
    ogUrl: url,
    ogImage,
    twitterCard: 'summary_large_image',
    twitterTitle: twitterMetaData?.title ?? data?.metaTitle,
    twitterDescription: twitterMetaData?.description ?? data?.metaDescription,
    twitterImage,
    twitterImageAlt:
      twitterMetaData?.image?.data?.attributes?.alternativeText ?? data?.metaImage?.data?.attributes?.alternativeText,
    twitterSiteId: 'Onliz_fr',
    twitterCreator: 'Onliz_fr',
  });
}
